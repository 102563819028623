<template>
  <main>
    <package-header title="Searches &amp; STCs" subTitle="12764 Smith"></package-header>

    <div class="container-fluid">
      <div class="row">

        <div class="col-lg-2">

          <!-- Package navigation hamburger -->
          <button class="btn btn-secondary d-lg-none mb-2" data-toggle="collapse" data-target="#package-nav" aria-expanded="false" aria-controls="package-nav">
            <svg class="icon icon-menu"><use xlink:href="/icons/symbol-defs.svg#icon-menu"></use></svg>
            <svg class="icon icon-close"><use xlink:href="/icons/symbol-defs.svg#icon-close"></use></svg>
            Navigate Package
          </button>

          <div class="collapse collapse-lg" id="package-nav">
            <package-navigation></package-navigation>
          </div>
        </div>

        <div class="col">

          <!-- Tabs navigation -->
          <ul class="nav nav-tabs mb-4" role="tablist">
            <li class="nav-item">
              <router-link to="/pages/searches-stc/purchase" class="nav-link">
                Purchase
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/pages/searches-stc/manage-existing" class="nav-link active">
                Manage Purchases
              </router-link>
            </li>
          </ul>

          <!-- Main package content -->
          <div class="row">

            <!-- Data entry form -->
            <div class="col-12">

              <p class="lead">View existing orders for this package, or cancel a pending order.</p>

              <div class="table-stacked">
                <table class="table table-hover mt-4">
                  <thead class="bg-light">
                    <tr>
                      <th scope="row">PID and Short Legal Description</th>
                      <th scope="col">Title</th>
                      <th scope="col" class="active-sort">Product Type <span class="table-sort"></span></th>
                      <th scope="col">Issue Time <span class="table-sort"></span></th>
                      <th scope="col">Pending Fees</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in tableItems">
                      <td data-header="PID and Short Legal Description">
                        <span class="td-content">{{ item.a }}</span>
                      </td>
                      <td data-header="Title">
                        <span class="td-content"><a href="javascript:void(0)">{{ item.b }}</a></span>
                      </td>
                      <td data-header="Product Type">
                        <span class="td-content">{{ item.c }}</span>
                      </td>
                      <td data-header="Issue Time">
                        <span class="td-content">{{ item.d }}</span>
                      </td>
                      <td data-header="Pending Fees">
                        <span class="td-content">{{ item.e }}</span>
                      </td>
                      <td data-header="Action">
                        <span class="td-content"><a href="javascript:void(0)" :class="item.g">{{ item.f }}</a></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Scroll to top -->
      <a href="#top" class="d-block float-right d-print-none scroll-to-top">
        <svg class="icon icon-arrow_upward"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_upward"></use></svg>Back to Top
      </a>
    </div>

    <help-panel title="Searches &amp; STCs"></help-panel>
  </main>
</template>

<script>

var filters = {
  active: function (tableItems) {
    return tableItems.filter(function (tableItem) {
      return tableItem.selected
    })
  }
}

import PackageHeader from '../../../components/PackageHeader.vue'
import PackageNavigation from '../../../components/PackageNavigation.vue'
import HelpPanel from '../../../components/HelpPanel.vue'

export default {

  components: {
    'package-header': PackageHeader,
    'package-navigation': PackageNavigation,
    'help-panel': HelpPanel
  },

  data () {
    return {
      preSearch: false,
      postSearch: false,
      tableItems: [
        {
          a:'015-671-569 S/1592///A//8',
          b:'KT119399 NE',
          c:'Title Search',
          d:'Pending submission',
          e:'$9.45',
          f:'Cancel',
          g:'text-danger'
        },
        {
          a:'015-671-570 S/1592///A//9',
          b:'View subscription',
          c:'Parcel Activity Notifier Subscription',
          d:'Sep 21 2017 2:41 PM',
          e:'',
          f:'',
          g:''
        },
        {
          a:'015-671-572 S/1592///A//11',
          b:'KT119321 NE',
          c:'Certification',
          d:'Pending registration',
          e:'$14.31',
          f:'Cancel',
          g:'text-danger'
        },
        {
          a:'015-671-572 S/1592///A//12',
          b:'KT119403 NE',
          c:'Title Search',
          d:'Sep 21 2017 2:41 PM',
          e:'',
          f:'',
          g:''
        }
      ]
    }
  },
  methods: {

  }
}
</script>

